






















































































































































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListWarehouseTransfer } from "@/models/interface/warehouse.interface";
import { logisticServices } from "@/services/logistic.service";
import Vue from "vue";
import moment, { Moment } from "moment";
import {
  OptionModel,
  ResponsePagination,
} from "@/models/constant/interface/common.interface";
import {
  DataListProduct,
  DataWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { debounceProcess } from "@/helpers/debounce";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import printJs from "print-js";
import { masterServices } from "@/services/master.service";
export default Vue.extend({
  name: "WarehouseTransferList",
  data() {
    this.getListLocation = debounceProcess(this.getListLocation, 400);
    this.getPartNumber = debounceProcess(this.getPartNumber, 400);
    this.getList = debounceProcess(this.getList, 400);
    this.findListDocument = debounceProcess(this.findListDocument, 400);
    return {
      DEFAULT_DATE_FORMAT,
      dataList: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as ResponseListWarehouseTransfer,
      columnsTable: [
        {
          title: "Document Number",
          dataIndex: "documentNumber",
          key: "documentNumber",
          sorter: false,
          width: "18%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: "Source",
          dataIndex: "locationSource",
          key: "locationSource",
          sorter: false,
          width: "18%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: "Destination",
          dataIndex: "locationDestination",
          key: "locationDestination",
          sorter: false,
          width: "18%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: "Part Number",
          dataIndex: "productCode",
          key: "productCode",
          sorter: false,
          width: "18%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: "Transfer Date",
          dataIndex: "date",
          key: "date",
          sorter: false,
          width: "14%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: "Received Date",
          dataIndex: "receivedDate",
          key: "receivedDate",
          sorter: false,
          width: "14%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: "Status",
          dataIndex: "state",
          key: "state",
          sorter: false,
          width: "14%",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          key: "operation",
          width: "10%",
          scopedSlots: { customRender: "operation" },
          button: ["update"],
          align: "center",
        },
      ],
      limitPage: 10 as number,
      page: 1 as number,
      search: "" as string,
      params: "" as string,
      sortBy: "warehouseTransfer.date:desc" as string,
      filterList: {
        docNumber: "",
        status: undefined,
        source: "",
        destination: "",
        date: [],
        partNumber: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
      form: {
        rules: {
          docNumber: {
            label: "lbl_document_number",
          },
          status: {
            label: "lbl_status",
          },
          source: {
            label: "lbl_source",
          },
          destination: {
            label: "lbl_destination",
          },
          date: {
            label: "lbl_date",
          },
          partNumber: {
            label: "lbl_part_number",
            placeholder: "lbl_part_number_placeholder",
          },
        },
        model: {
          docNumber: "",
          status: "",
          source: "",
          destination: "",
          partNumber: "",
          date: [] as Moment[],
        },
      },
      listWarehouseState: [] as OptionModel[],
      dataListLocation: [] as DataWarehouseLocation[],
      dataPartCode: [] as DataListProduct[],
      listDocumentNumber: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as ResponseListWarehouseTransfer,
      loading: {
        docNumber: false as boolean,
        source: false as boolean,
        destination: false as boolean,
        loadingTable: false as boolean,
        loadingDownload: false as boolean,
        loadingPrint: false as boolean,
        status: false,
      },
    };
  },
  mounted() {
    this.getListLocation("");
    this.findListDocument("");
    this.getPartNumber("");
    this.clearFilter();
    this.getListStatus();
  },
  methods: {
    moment,
    getListStatus(): void {
      this.loading.status = true;
      masterServices
        .listMaster({ name: "WAREHOUSE_TRANSFER" })
        .then(res => {
          this.listWarehouseState = res.map(item => ({
            label: item.value,
            value: item.value,
          }));
        })
        .finally(() => (this.loading.status = false));
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      const findDataSource = this.dataListLocation.find(
        dataFind => dataFind.id === res["source"]
      );
      const findDataDestination = this.dataListLocation.find(
        dataFind => dataFind.id === res["destination"]
      );
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(`,${res["docNumber"]}`);
      params += this.checkValue(`,${res["status"]}`);
      params += this.checkValue(
        `,${findDataSource?.warehouse.branchWarehouse.name} - ${findDataSource?.warehouse.name} ${findDataSource?.name}`
      );
      params += this.checkValue(
        `,${findDataDestination?.warehouse.branchWarehouse.name} - ${findDataDestination?.warehouse.name} ${findDataDestination?.name}`
      );
      params += this.checkValue(
        `,${
          res["date"] && res["date"].length > 0
            ? this.moment(res["date"][0]).format(DEFAULT_DATE_FORMAT) +
              " - " +
              this.moment(res["date"][1]).format(DEFAULT_DATE_FORMAT)
            : undefined
        }`
      );
      params += this.checkValue(`,${res["partNumber"]}`);

      return params;
    },
    handleDownload() {
      this.findData();
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
      };
      this.loading.loadingDownload = true;
      params.search = this.search;
      params.params = this.params;
      logisticServices
        .getDownloadWarehouseTransfer(params)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "warehouse_transfer.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.loadingDownload = false));
    },
    handlePrint() {
      this.findData();
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
      };
      params.search = this.search;
      params.params = this.params;
      this.loading.loadingPrint = true;
      logisticServices
        .getPrintWarehouseTransfer(params)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            printJs(url);
          }
        })
        .finally(() => (this.loading.loadingPrint = false));
    },
    responseEditTable(row: any): void {
      this.$router.push(`/logistic/warehousetransfer?id=${row.data.id}`);
    },
    createNew(): void {
      this.$router.push(`/logistic/warehousetransfer`);
    },
    findListDocument(valueSearch: string): void {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch) {
        params.search = `warehouseTransfer.documentNumber~${valueSearch}`;
      }
      this.loading.docNumber = true;
      logisticServices
        .listWarehouseTransfer(params)
        .then((res: ResponseListWarehouseTransfer) => {
          res.data = res.data.filter(
            (value, index, self) =>
              index ===
              self.findIndex(t => t.documentNumber === value.documentNumber)
          );
          res.data.forEach((x, idx) => {
            x["key"] = idx;
            this.listDocumentNumber = res;
          });
        })
        .finally(() => (this.loading.docNumber = false));
    },
    // part number = part code kalau di warehouse transfer (kata mas ucup)
    getPartNumber(valueSearch) {
      const params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `code~*${valueSearch}*`;

      logisticServices.listProduct(params).then(data => {
        this.dataPartCode = data.data;
      });
    },
    getList(params?: RequestQueryParamsModel): void {
      if (!params) {
        params = {
          page: this.page - 1,
          limit: this.limitPage,
          sorts: this.sortBy,
        };
      }

      if (this.search) {
        params.search = this.search;
      } else {
        delete params.search;
      }
      this.loading.loadingTable = true;
      logisticServices
        .listWarehouseTransfer(params)
        .then((res: ResponseListWarehouseTransfer) => {
          res.data.forEach((x, idx) => {
            x["key"] = idx;
            x["sourceName"] = x.sourceLocation || "-";
            x["destinationName"] = x.destinationLocation || "-";
          });
          this.dataList = res;
        })
        .finally(() => (this.loading.loadingTable = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limitPage = response.size;
      this.page = 1;
      this.getList();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getList();
    },
    getListLocation(valueSearch: string) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };

      if (valueSearch) {
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      }
      this.loading.source = true;
      this.loading.destination = true;
      logisticServices
        .listWarehouseLocation(params, "")
        .then(data => {
          this.dataListLocation = data.data;
        })
        .finally(() => {
          this.loading.destination = false;
          this.loading.source = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    findData(): void {
      this.filterList = this.form.model as any;
      const searchDocNumber = `warehouseTransfer.documentNumber~${this.filterList.docNumber}`;
      const searchState = `warehouseTransfer.state~${this.filterList.status}`;
      const searchSource = `warehouseTransfer.source.secureId~${this.filterList.source}`;
      const searchDestination = `warehouseTransfer.destination.secureId~${this.filterList.destination}`;
      const timeFrom = this.filterList.date?.length
        ? this.moment(this.filterList.date[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()
        : "";
      const timeTo = this.filterList.date?.length
        ? this.moment(this.filterList.date[1])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()
        : "";
      const searchDate = `warehouseTransfer.date>=${timeFrom}_AND_warehouseTransfer.date<=${timeTo}`;
      const searchPartNumber = `product.code~*${this.filterList.partNumber}*`;
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        sorts: this.sortBy,
      };
      if (this.filterList.status) {
        params.search = params.search
          ? params.search.concat("_AND_", searchState)
          : searchState;
      }
      if (this.filterList.date) {
        params.search = params.search
          ? params.search.concat("_AND_", searchDate)
          : searchDate;
      }
      if (this.filterList.source) {
        params.search = params.search
          ? params.search.concat("_AND_", searchSource)
          : searchSource;
      }
      if (this.filterList.destination) {
        params.search = params.search
          ? params.search.concat("_AND_", searchDestination)
          : searchDestination;
      }
      if (this.filterList.partNumber) {
        params.search = params.search
          ? params.search.concat("_AND_", searchPartNumber)
          : searchPartNumber;
      }
      if (this.filterList.docNumber) {
        params.search = params.search
          ? params.search.concat("_AND_", searchDocNumber)
          : searchDocNumber;
      }
      this.search = params.search || "";
      this.params = this.checkParams(this.filterList);
      this.getList(params);
    },
    clearFilter(): void {
      for (const key in this.form.model) {
        if (Object.prototype.hasOwnProperty.call(this.form.model, key)) {
          this.form.model[key] = undefined;
        }
      }
      this.search = "";
      this.limitPage = 10;
      this.dataList.data = [];
    },
    onRangePickerChange(dates: Moment[]): void {
      if (!dates.length) {
        this.form.model.date = [];
      }
    },
  },
});
